import React, { useEffect } from 'react'
import { cookies } from 'scripts/cookies'
import { AppType } from 'types/App'
import { ConnectionType } from 'types/Connection'
import { navigate } from 'gatsby'
import { getValidateUserIdentity } from 'api/getValidateUserIdentity'
import { SendFormBasedAuthExecParamsToExtension } from 'scripts/sendMessageToExtension'
import { getLoginScript } from 'api/getLoginScript'
import { isOpenInLockedApp } from 'scripts/isOpenInLockedApp'
import { getDecryptedScript } from 'scripts/getDecryptedScript'
import { sentryCaptureMessage } from 'scripts/sentryCaptureMessage'

interface Props {
  app: Partial<AppType>
  connection: ConnectionType
  acId: string
}

const Index: React.FC<Props> = ({ app, connection, acId }) => {
  const sessionToken = cookies.get('session_token')
  const navigateToAppsByFail = (snackbarText: string) => {
    navigate('/apps', {
      state: {
        snackbarText,
      },
      replace: true,
    })
    return
  }

  useEffect(() => {
    const transfer = async () => {
      if (!(app?.id && sessionToken)) {
        navigateToAppsByFail('ログインに失敗しました')
        return
      }

      if (typeof connection.custom_data.login_url === 'undefined') {
        navigateToAppsByFail(
          'アプリの設定が不足しています。LOCKEDサポート担当までご連絡ください。',
        )
        return
      }

      const res = await getValidateUserIdentity({
        acId,
        connId: connection.id,
      })

      if (res.status !== 200) {
        navigateToAppsByFail(
          'ログイン情報が入力されていません。管理者に確認をお願いします。',
        )
        return
      }

      const params = {
        sessionToken: sessionToken,
        appId: app.id,
        connId: connection.id,
        acId: acId,
      }

      if (isOpenInLockedApp) {
        const loginItems = await getLoginScript({
          appId: app.id,
          connId: connection.id,
          sessionToken,
          acId,
        })
        const scripts = getDecryptedScript(
          loginItems.data.login_items,
          sessionToken,
        )
        scripts.forEach((s) => PushLoginScript?.postMessage(s))
        location.replace(connection.custom_data.login_url)
      } else {
        const result = await new SendFormBasedAuthExecParamsToExtension(
          params,
        ).exec()
        if (!result.success) {
          sentryCaptureMessage(
            'There is no response to the message sent from the Auth-client to the Chrome-extension, but processing may continue. Auth-client is trying to access the URL of the application to login.',
            {
              app: app,
              connection: connection,
              acId: acId,
            },
          )
        }
        location.replace(connection.custom_data.login_url)
      }
      return
    }

    transfer()
  }, [])

  return <></>
}

export default Index
