import { AxiosResponse } from 'axios'
import { fullUrl } from 'scripts/fullUrl'
import { checkAppAuthorityResponse } from 'types/ApiResponse'
import { checkAppAuthorityRequest } from 'types/ApiRequest'
import { axiosClient } from 'scripts/api'

export const checkAppAuthority = async (
  params: checkAppAuthorityRequest,
): Promise<AxiosResponse<checkAppAuthorityResponse>> => {
  const res = await axiosClient.get<checkAppAuthorityResponse>(
    `${fullUrl()}/apps/${params.app_id}/authorize`,
    { params },
  )
  return res
}
