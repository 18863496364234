import { GetLoginScriptRequest } from 'types/ApiRequest'
import { axiosClient } from 'scripts/api'
import { cookies } from 'scripts/cookies'
import { LoginItemTypes } from 'types/LoginItem'
import { fullUrl } from 'scripts/fullUrl'

export const getLoginScript = async (params: GetLoginScriptRequest) => {
  const session_token = cookies.get('session_token')
  const res = await axiosClient.get<{ login_items: LoginItemTypes[] }>(
    `${fullUrl('api')}/connections/form_based/login_script`,
    {
      params: {
        session_token,
        conn_id: params.connId,
        ac_id: params.acId,
        app_id: params.appId,
      },
    },
  )
  return res
}
