import { GetValidateUserIdentityResponse } from 'types/ApiResponse'
import { GetValidateUserIdentityRequest } from 'types/ApiRequest'
import { fullUrl } from 'scripts/fullUrl'
import { axiosClient } from 'scripts/api'
import { cookies } from 'scripts/cookies'

export const getValidateUserIdentity = async (
  params: GetValidateUserIdentityRequest,
) => {
  const session_token = cookies.get('session_token')
  const res = await axiosClient.get<GetValidateUserIdentityResponse>(
    `${fullUrl()}/endusers/validate_user_identity`,
    { params: { session_token, conn_id: params.connId, ac_id: params.acId } },
  )
  return res
}
