import { AxiosResponse } from 'axios'
import { fullUrl } from 'scripts/fullUrl'
import { ebaseRedirectUrlResponse } from 'types/ApiResponse'
import { ebaseRedirectUrlRequest } from 'types/ApiRequest'
import { axiosClient } from 'scripts/api'

export const ebaseRedirectUrl = async (
  params: ebaseRedirectUrlRequest,
): Promise<AxiosResponse<ebaseRedirectUrlResponse>> => {
  const res = await axiosClient.get<ebaseRedirectUrlResponse>(
    `${fullUrl()}/apps/connection/ebase/redirect_url`,
    { params },
  )
  return res
}
