import * as Sentry from '@sentry/gatsby'

export const sentryCaptureMessage = (
  message: string,
  data?: Record<string, any>,
): void => {
  Sentry.captureMessage(message, (scope) => {
    if (data) scope.setContext('extra-data', { data })
    return scope
  })
}
