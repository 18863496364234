import React from 'react'
import Img from 'images/Loading.gif'
import CSS from 'csstype'

interface Props {
  style?: CSS.Properties
  width?: number
  height?: number
}

export const Loading: React.FC<Props> = ({ style, width, height }) => {
  return (
    <img
      style={{ ...style }}
      width={width}
      height={height}
      data-test="loadingComponent"
      src={Img}
    />
  )
}
