import React, { useEffect } from 'react'
import { cookies } from 'scripts/cookies'
import { AppType } from 'types/App'
import { ConnectionType } from 'types/Connection'
import { ebaseRedirectUrl } from 'api/ebaseRedirectUrl'
import { navigate } from 'gatsby'

interface Props {
  app: Partial<AppType>
  connection: ConnectionType
  acId?: string
}

const Index: React.FC<Props> = ({ app, connection, acId }) => {
  const sessionToken = cookies.get('session_token')
  const navigateToAppsByFail = () => {
    navigate('/apps', {
      state: {
        snackbarText: 'ログインに失敗しました',
      },
      replace: true,
    })
    return
  }

  useEffect(() => {
    const transfer = async () => {
      if (!(app?.id && sessionToken)) {
        navigateToAppsByFail()
        return
      }

      try {
        const res = await ebaseRedirectUrl({
          app_id: app.id,
          session_token: sessionToken,
          ac_id: acId,
        })

        if (res.status === 200) {
          location.replace(res.data.url)
        } else {
          navigateToAppsByFail()
          return
        }
      } catch (e) {
        navigateToAppsByFail()
        return
      }
    }

    transfer()
  }, [])

  return <></>
}

export default Index
