// 特殊なimport
// https://stackoverflow.com/a/52618652/10677105
import SHA256 from 'crypto-js/sha256'
import Base64 from 'crypto-js/enc-base64'
import Utf8 from 'crypto-js/enc-utf8'
import AES from 'crypto-js/aes'
import { LoginItemTypes } from 'types/LoginItem'

export const getDecryptedScript = (
  loginItems: LoginItemTypes[],
  lockedSessionToken: string,
): string[] => {
  return loginItems.map((item) => {
    const { crypted_base64, iv_base64 } = item
    const key = SHA256(lockedSessionToken)
    const crypted = Base64.parse(crypted_base64)
    const iv = Base64.parse(iv_base64)
    const decrypted = AES.decrypt({ ciphertext: crypted } as any, key, {
      iv: iv,
    })
    const script = Utf8.stringify(decrypted)
    return script
  })
}
