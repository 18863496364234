import { SendMessageToExtension } from '../common'

export class SendFormBasedAuthExecParamsToExtension extends SendMessageToExtension {
  readonly message: {
    sessionToken?: string
    appId?: number
    connId?: number
    acId?: string
  }
  constructor(
    message: {
      sessionToken?: string
      appId?: number
      connId?: number
      acId?: string
    },
    option?: { timeout?: number },
  ) {
    super(option)

    this.message = message
  }
}
