import { userAgentLockedApp } from './userAgents'

const hasE2eUserAgent = () => {
  const isDev = process.env.GATSBY_ACTIVE_ENV === 'development'
  const hasLockedAppUserAgent =
    typeof navigator !== 'undefined' &&
    navigator.userAgent === userAgentLockedApp
  if (isDev && hasLockedAppUserAgent) {
    return true
  } else {
    return false
  }
}

export const isOpenInLockedApp =
  typeof JudgeOpenInLockedApp === 'object' || hasE2eUserAgent()
