import React, { useEffect } from 'react'
import { cookies } from 'scripts/cookies'
import { AppType } from 'types/App'
import { ConnectionType } from 'types/Connection'
import { navigate } from 'gatsby'

interface Props {
  app: Partial<AppType>
  connection: ConnectionType
}

export const BookmarkTransfer: React.FC<Props> = ({ app, connection }) => {
  const sessionToken = cookies.get('session_token')
  const navigateToAppsByFail = (snackbarText: string) => {
    navigate('/apps', {
      state: {
        snackbarText,
      },
      replace: true,
    })
    return
  }

  useEffect(() => {
    const transfer = async () => {
      if (!(app?.id && sessionToken)) {
        navigateToAppsByFail('ログインに失敗しました')
        return
      }

      if (typeof connection.custom_data.login_url !== 'string') {
        navigateToAppsByFail(
          'アプリの設定が不足しています。LOCKEDサポート担当までご連絡ください。',
        )
        return
      }

      location.replace(connection.custom_data.login_url)
      return
    }

    transfer()
  }, [])

  return null
}
