import React, { useEffect } from 'react'

interface Props {
  samlResponse: string
  relayState: string
  redirectUrl: string
}

const Index: React.FC<Props> = ({ samlResponse, relayState, redirectUrl }) => {
  useEffect(() => {
    document.forms[0].submit()
  }, [])

  return (
    <form method="POST" action={redirectUrl}>
      <input name="SAMLResponse" type="hidden" value={samlResponse} />
      <input name="RelayState" type="hidden" value={relayState} />
    </form>
  )
}

export default Index
