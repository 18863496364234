import React, { useEffect } from 'react'
import { cookies } from 'scripts/cookies'
import { AppType } from 'types/App'
import { ConnectionType } from 'types/Connection'
import { navigate } from 'gatsby'
import SamlSpInit from 'presentation/pages/transfer/Saml/SpInitiated'
import SamlIdpInit from 'presentation/pages/transfer/Saml/IdpInitiated'

interface Props {
  app: Partial<AppType>
  connection: ConnectionType
  acId?: string
  samlRequest?: string | null | undefined
  relayState?: string | null | undefined
}

const Index: React.FC<Props> = ({
  app,
  connection,
  acId,
  samlRequest,
  relayState,
}) => {
  // saml 遷移パターン
  // 1. idpアプリをクリック→saml_response生成してpost
  // 2. spアプリをクリック→samlではなくlogin_urlに遷移
  // 3. spからsaml_request→saml_response生成してpost
  const sessionToken = cookies.get('session_token')
  const navigateToAppsByFail = () => {
    navigate('/apps', {
      state: {
        snackbarText: 'ログインに失敗しました',
      },
      replace: true,
    })
    return
  }

  useEffect(() => {
    if (!app?.id) {
      navigateToAppsByFail()
      return
    }
  }, [])

  // 1. idp-initiated
  if (connection.custom_data.can_idp_initiated && !samlRequest) {
    return (
      <SamlIdpInit
        sessionToken={sessionToken!}
        samlRequest={samlRequest}
        relayState={relayState || connection.custom_data.relay_state} // ToTransferStateからrelayStateが渡されなかったら、APIに保持されたものを使う
        app={app}
        connection={connection}
        acId={acId}
      ></SamlIdpInit>
    )
  }

  // 2. sp click
  if (connection.custom_data.can_sp_initiated && !samlRequest) {
    if (typeof connection.custom_data.login_url === 'undefined') {
      navigateToAppsByFail()
      return <></>
    } else {
      location.replace(connection.custom_data.login_url)
      return <></>
    }
  }

  // 3. sp-initiated
  // NOTE: RelayStateはジョブカンになかった
  if (connection.custom_data.can_sp_initiated && samlRequest) {
    return (
      <SamlSpInit
        sessionToken={sessionToken!}
        samlRequest={samlRequest}
        relayState={relayState || connection.custom_data.relay_state} // ToTransferStateからrelayStateが渡されなかったら、APIに保持されたものを使う
        app={app}
        connection={connection}
        acId={acId}
      ></SamlSpInit>
    )
  }

  // どのパターンでもない場合
  navigateToAppsByFail()

  return <></>
}

export default Index
