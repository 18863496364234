import React, { useEffect, useState } from 'react'
import { useQueryParam, NumberParam, StringParam } from 'use-query-params'
import { cookies } from 'scripts/cookies'
import EbaseTransfer from './Ebase'
import SamlTransfer from './Saml'
import FormBasedAuthTransfer from './FormBasedAuth'
import { navigate, PageProps } from 'gatsby'
import axios from 'axios'
import { GetAppResponse } from 'types/ApiResponse'
import { fullUrl } from 'scripts/fullUrl'
import { AppType } from 'types/App'
import { ConnectionType } from 'types/Connection'
import { checkAppAuthority } from 'api/checkAppAuthority'
import { ToTransferState } from 'types/NavigateState'
import { Loading } from 'presentation/components/Loading'
import { BookmarkTransfer } from './Bookmark'

export const Transfer = (props: PageProps) => {
  const navigateState: ToTransferState = props.location.state as ToTransferState

  const sessionToken = cookies.get('session_token')
  const [appId] = useQueryParam('app_id', NumberParam)
  const [acId] = useQueryParam('ac_id', StringParam)

  const [app, setApp] = useState<Partial<AppType>>()
  const [connection, setConnection] = useState<ConnectionType>()
  const [loading, setLoading] = useState<boolean>(true)
  const [accessAllowed, setAccessAllowed] = useState(false)

  const navigateToAppsByFail = (
    snackbarText: string,
    snackbarAttribute?: string,
  ) => {
    navigate('/apps', {
      state: {
        snackbarText: snackbarText,
        snackbarAttribute: snackbarAttribute,
      },
      replace: true,
    })
    return
  }

  useEffect(() => {
    axios
      .get<GetAppResponse>(`${fullUrl()}/apps/${appId}`, {
        params: {
          session_token: sessionToken,
          app_id: appId ?? undefined,
        },
      })
      .then((response) => {
        setApp(response.data.app)
        setConnection(response.data.connection)
      })
      .catch((e) => {
        // error
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  useEffect(() => {
    // 権限チェック
    checkAppAuthority({
      app_id: appId ?? undefined,
      session_token: cookies.get('session_token'),
    })
      .then((response) => {
        if (response.data.authorized) {
          setAccessAllowed(true)
        } else {
          if (response.data.reason === '$app_access_rule_match') {
            navigateToAppsByFail(
              '管理者が設定したルールによってログインが失敗しました',
              'appAccessRuleDenySnackbar',
            )
            return
          } else if (response.data.reason === '$device_is_locked') {
            navigateToAppsByFail(
              '端末が凍結されているためアプリへアクセスできませんでした',
            )
            return
          } else if (
            response.data.reason === '$user_is_not_authorized_access_to_app'
          ) {
            navigateToAppsByFail('アプリへのアクセス権限がありません')
            return
          } else {
            navigateToAppsByFail('アプリへアクセスできませんでした')
            return
          }
        }
      })
      .catch((e) => {
        navigateToAppsByFail('アプリへアクセスできませんでした')
        return
      })
  }, [])

  if (loading) {
    return (
      <main
        data-test="loadingTransferPage"
        className="pb-20 pt-10 w-full flex justify-center"
      >
        <Loading />
      </main>
    )
  } else if (!loading && typeof app === 'undefined') {
    navigateToAppsByFail('ログインに失敗しました')
    return
  }

  const AppTransfer = () => {
    if (app && connection && accessAllowed) {
      if (connection.conn_type === 'ebase') {
        return (
          <EbaseTransfer
            app={app}
            connection={connection}
            acId={acId ?? undefined}
          ></EbaseTransfer>
        )
      } else if (connection.conn_type === 'form_based_auth') {
        if (typeof acId === 'string') {
          return (
            <FormBasedAuthTransfer
              app={app}
              connection={connection}
              acId={acId}
            ></FormBasedAuthTransfer>
          )
        } else {
          // ここに入ったらバグ
          navigate('/apps', {
            state: {
              snackbarText:
                'アプリのログイン情報の設定が不足しています。LOCKEDサポート担当までご連絡ください。',
            },
            replace: true,
          })
          return <></>
        }
      } else if (connection.conn_type === 'bookmark') {
        return <BookmarkTransfer app={app} connection={connection} />
      } else if (connection.conn_type === 'saml') {
        return (
          <SamlTransfer
            app={app}
            connection={connection}
            acId={acId ?? undefined}
            samlRequest={navigateState?.samlRequest}
            relayState={navigateState?.relayState}
          ></SamlTransfer>
        )
      }
    }

    return <div></div>
  }

  return (
    <>
      <AppTransfer></AppTransfer>
    </>
  )
}
