import React, { useEffect, useState } from 'react'
import { AppType } from 'types/App'
import { ConnectionType } from 'types/Connection'
import { SamlIdpInitResponse } from 'types/ApiResponse'
import { fullUrl } from 'scripts/fullUrl'
import SamlPost from 'presentation/components/Saml/Post'
import { axiosClient } from 'scripts/api'

interface Props {
  sessionToken: string
  app: Partial<AppType>
  connection: ConnectionType
  acId?: string | undefined
  samlRequest?: string | null | undefined
  relayState?: string | null | undefined
}

const Index: React.FC<Props> = ({ sessionToken, app, acId, relayState }) => {
  const [samlResponse, setSamlResponse] = useState<string>()
  const [redirectUrl, setRedirectUrl] = useState<string>()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    axiosClient
      .get<SamlIdpInitResponse>(`${fullUrl()}/saml/idpinit`, {
        params: {
          session_token: sessionToken,
          app_key: app.client_key,
          ac_id: acId,
        },
        headers: {
          Accept: 'application/json',
        },
      })
      .then((response) => {
        setRedirectUrl(response.data.redirect_url)
        setSamlResponse(response.data.saml_response)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  if (!loading && samlResponse) {
    return (
      <SamlPost
        redirectUrl={redirectUrl!}
        samlResponse={samlResponse!}
        relayState={relayState!}
      ></SamlPost>
    )
  }

  return <></>
}

export default Index
